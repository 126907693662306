<div class="container is-widescreen">
  <div class="columns">

    <div class="column is-5">
      <div class="content is-legal">
        <h2 class="title is-6">{{today | date:'yyyy'}} &copy; {{companyName}} - {{companyExtended}}</h2>
        <p>Vi respekterer og beskytter personvernet og personopplysningene dine. Les om bruk av <a class="button-link" [routerLink]="['/informasjonskapsler']" [routerLinkActive]="['active']">informasjonskapsler (cookies)</a> og om hvordan {{companyName}} bruker <a class="button-link" [routerLink]="['/personvern']" [routerLinkActive]="['active']">personopplysninger</a>.</p>
        <p><a class="button-link" href="https://uustatus.no/nb/erklaringer/publisert/8c87c52b-552d-4654-9682-ce82c08ea698" target="_blank" rel="noreferrer">Tilgjengelighetserklæring</a></p>

      </div>

      <div class="is-social">
        <div class="columns is-mobile">
          <div class="column is-1">
            <a href="https://facebook.com/rvtsost/" target="_blank" rel="noopener">
              <span class="fab fa-facebook-square" aria-hidden="true"></span>
            </a>
          </div>

          <div class="column is-1">
            <a href="https://www.instagram.com/rvtsost/" target="_blank" rel="noopener">
              <span class="fab fa-instagram" aria-hidden="true"></span>
            </a>
          </div>

          <div class="column is-1">
            <a href="https://www.youtube.com/channel/UCuhN5YoyRRkoQqnHoLPNJZw" target="_blank" rel="noopener">
              <span class="fab fa-youtube" aria-hidden="true"></span>
            </a>
          </div>

        </div>
      </div>
    </div>

    <aside class="column is-contact">
      <div class="columns">
        <div class="column">
          <div class="column-item">
            <h4 class="title is-6">Besøksadresse</h4>
            <p>Gullhaugveien 1-3</p>
            <p>0484 Oslo - NO</p>
          </div>

          <div class="column-item">

            <h4 class="title is-6">Postadresse</h4>
            <p>Postboks 4623 Nydalen</p>
            <p>0405 Oslo - NO</p>
          </div>

          <div class="column-item">
            <h4 class="title is-6">EHF Faktura</h4>
            <p>980 633 004</p>
          </div>
        </div>

        <div class="column">
          <div class="column-item">

            <h4 class="title is-6">Telefon</h4>
            <p>
              <a class="button-link" href="tel:22586000">22 58 60 00</a>
            </p>
          </div>

          <div class="column-item">
            <h4 class="title is-6">Epost</h4>
            <p><a class="button-link" href="mailto:post@rvtsost.no">post@rvtsost.no</a></p>
          </div>

          <div class="column-item">
            <h4 class="title is-6">Ansvarlig redaktør</h4>
            <p><a class="button-link" [routerLink]="['/medarbeidere/solveig-espeland-ertresvaag']" [routerLinkActive]="['active']">Kommunikasjonsansvarlig</a></p>
          </div>


          </div>
      </div>
    </aside>

  </div>
</div>
